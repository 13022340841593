$primary-color-old: #E94F1C;
$secondary-color: #1D1D1B;
$button-color: #666666;

$text-color: #939598;
$text-color-1: #292666;
$text-color-2: #2A3F54;
$text-color-3: #8D939B;
$text-disabled: #ACB2BA;
$link-color: #F2F1FF;
$link-color-1: #F8F8F8;
$grey-1: #ACB2BA;
$grey-2: #D1D1D1;
$grey-3: #F2F2F2;
$grey-4: #F5F5F5;
$grey-5: #F1F1F1;
$grey-6: #FAFAFA;
$grey-7: #f8f8f8;
$white-color: white;
$black-color: #000;
$status-green: #1BD365;
$status-red: #FF3C3C;
$border-color: #EDEDED;
$border-color-1: #E9E9E9;
$highlight-color: #00000008;
$input-border-color: rgba(0, 0, 0, 0.15);
$role-permission-background: #efefef;
$role-permission-text: hsl(0, 0%, 20%);
$role-permission-cross-background: rgb(255, 189, 173);

$secondary-light: #898A93;
$secondary-main: #1A1C1E;
$grey_color_1: #2B2B2B;
$grey_color_3: #898A93;
$grey_color_2: #545454;
$grey_color_4: #F3F4F8;
$grey_color_5: #313133;
$grey_color_6: #909090;
$blue-color: #0166FF;
$disabled-back: rgba(209, 215, 225, 0.4);
$border_grey: #E0E3EA;
$dark-grey: #BFC4D0;
$tabs-bg: #F2F6FA;
$tabs-btn-box-shadow: #6A738726;
$tabs-btn-inactive: #8F9BB7;
$disabled-text-color: #b6bdcb;

// notification common component variables
$light-grey: #ACB2BA;
$table-header: #efefef;
$table-td-color: #212529;
$light-orange: #F39677;

//NEW UI COLORS
$background: #1A1C1E;
$grey_1: #2B2B2B;
$grey_3: #898A93;
$grey_2: #545454;
$grey_4: #BFC4D0;
$grey_5: #595858;
$disabled-back: rgba(209, 215, 225, 0.4);
$border-color-2: #E0E3EA;
$primary-color: #FF6D33;
$disabled-text-color: #b6bdcb;

:export {
    primaryColor: $primary-color;
    primaryColorOld: $primary-color-old;
    secondaryColor: $background;
    greyColor3: $grey_3;
    greyColor4: $grey_4;
    greyColor1: $grey_1;
    greyColor2: $grey_2;
    greyColor5: $grey_5;
    whiteColor: $white-color;
    disabledColor: $disabled-back;
    borderColor: $border-color-2;
    disabledText: $disabled-text-color;
}