@import "./sass/main.scss";
$fontFamily: Montserrat, sans-serif;
$fontFamilyMonsterrat: "Montserrat";

.notification-dialog {

  .title-container {
    padding: 0.5rem;
    max-width: 75vw !important;

    .title {
      font-family: $fontFamily;
      font-size: 1.2rem;
      font-weight: 500;
      max-width: 75vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .notifcation-title {
      color: $secondary-color;
    }
  }

  .close-button {
    width: 1.75rem !important;
    margin-top: 0.3rem;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .sub-title {
    color: $primary-color;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .dialog-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view-all {
      font-family: $fontFamilyMonsterrat;
      font-weight: 500;
      text-transform: none;
      font-size: 0.9rem;
      line-height: 1rem;
      padding: 0.5rem;
      border: none;
    }
  }

  .cards {
    &:not(:last-child) {
      //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 0.5rem;
    }

    padding: 0.5rem 0;

    .badge {
      width: 100%;
      margin-top: 0.5rem;

      .MuiBadge-badge {
        min-width: 5px;
        height: 5px;
      }

    }

    .title-card {
      font-family: $fontFamily;
      font-size: 1.1rem;
      font-style: normal;
      max-height: 2.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-wrap: wrap;
      text-align: left;
      -webkit-box-orient: vertical;
    }

    .sub-title {
      font-size: 1rem;
      color: $light-grey;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      text-align: right;
      margin: 0.2rem 0;
    }
  }
}

.notification-main-box {

  .MuiPaper-root {
    padding: 0.35rem 0.75rem !important;
  }

  .MuiDialogContent-root {
    padding: 0rem 0.75rem 0.75rem 0rem;
  }

  .MuiDialogActions-root {
    padding: 0.1rem;
  }

  .view-all {
    padding-left: 0 !important;
    margin-left: -0.25rem;
  }
}

.alert-dialog-main {
  top: 2.5rem !important;
  right: 0 !important;
  left: unset !important;
  bottom: unset !important;

  .MuiPaper-root {
    width: 38rem;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    text-align: center;
  }

  .tab-panel {
    .MuiTabs-root {
      min-height: 2rem;
    }

    button {
      min-height: 2rem;
      text-transform: none;
      padding: 0rem;
    }
  }
}

.alert-dialog {
  overflow: hidden;

  .MuiPaper-root {
    width: 70rem;
    max-height: 50rem;
  }

  .MuiDialogContent-root {
    padding-left: 0;
    padding-bottom: 1rem;
    // border-top: 0.1rem solid $grey_color_5;
    // border-bottom: 0.1rem solid $grey_color_5;
  }

  ;

  .sticky-input-select {
    font-family: $fontFamily;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    text-align: center;
    margin: 2rem 0;
  }

  .sticky-input-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1;
    position: sticky;
    z-index: 1;
    width: 100%;
    top: 0;
    height: 5rem;
  }
}

.action-show-all-dialog {
  .view-all {
    font-family: $fontFamilyMonsterrat !important;
    font-weight: 600;
    text-transform: initial;
    font-size: 1rem;
  }

  .MuiPaginationItem-root {
    font-family: $fontFamily !important;
    font-size: 1rem !important;
  }

  .MuiBox-root {
    font-family: $fontFamily !important;
    line-height: 1.42;
    font-size: 1.16rem;
  }

  .Mui-selected:hover {
    background-color: transparent !important;
  }
}

.scai-common-component-table-container {
  // width: 70rem;
  // max-width: 70rem;
  overflow-x: auto;

  .table-notification {
    border-collapse: collapse;
    display: table;
    height: auto;
    overflow-x: auto;
    width: 100%;
    border: 0.125rem solid;
    border-radius: 1rem;

    tr {
      th {
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          cursor: pointer;
        }
      }
    }

    th,
    td {
      word-break: break-word;
      white-space: nowrap;
      padding: 0.6rem 1.3125rem;
      // border: 0.125rem solid;
      // border-radius: 1rem;
      font-family: Montserrat, sans-serif;
    }

    th {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: $secondary-color;
      background: $table-header;
      text-align: center;
      vertical-align: middle;
    }

    td {
      color: $table-td-color;
      font-size: 1.1rem;
      line-height: 1.25rem;
    }
  }
}

.circular-loader {
  color: $text-color !important;
}

.notify-title {
  font-weight: 400;
  font-size: 1.1rem !important;
  padding: 0.3rem 0 !important;
  font-family: 'Euclid Circular Regular', sans-serif !important;
}

.notify-icon-container {
  svg {
    scale: 1.06;
  }
}

.chip-icon-light path {
  stroke: #282A2A
}

.chip-icon-dark path {
  stroke: #FFFFFF
}

.sort-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.custom-select-container {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;

  .custom-select__placeholder,
  .custom-select__single-value {
    margin-top: -0.2rem !important;
  }

  .custom-select__indicator {
    color: $grey_color_6;
    margin-top: -0.15rem;
  }

  .custom-select__control--is-focused {
    min-width: 12rem !important;
  }

  .custom-select__control {
    max-height: 3.52rem !important;
    min-width: 12rem !important;
    min-height: unset !important;
  }

  .custom-select__placeholder {
    font-family: $fontFamilyMonsterrat;
    color: $secondary-light;
  }

  .custom-select__placeholder,
  .custom-select__single-value {
    font-size: 1rem !important;
    color: $grey_color_5;
  }

  .custom-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  // .custom-select__input {
  //   display: none;
  // }

  .custom-select__indicators {
    margin-right: 0.5rem
  }

  .custom-select__indicator-separator {
    margin-top: 18% !important;
    margin-bottom: 23% !important;
    display: none;
  }

  .custom-select__control {
    background-color: transparent;
    border-radius: 1rem !important;
    //border: 0.1rem solid $grey_color_5 !important;
    box-shadow: none !important;
  }

  .custom-select__value-container {
    height: 3.4375rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    .custom-select__multi-value__label {
      background-color: $table-header;
    }

    .custom-select__multi-value__remove {
      background-color: $table-header;

      &:hover {
        background-color: $role-permission-cross-background;
        color: $primary-color;
      }
    }

    // overflow-y: scroll !important;
  }

  .custom-select__value-container::-webkit-scrollbar {
    width: 0.313rem;
  }

  .custom-select__placeholder {
    color: $secondary-light;
    font-family: $fontFamilyMonsterrat;
  }

  .custom-select__single-value {
    font-size: 0.875rem;
    color: $secondary-light;
    font-family: $fontFamilyMonsterrat;
  }

  .custom-select__menu {
    z-index: 50 !important;

    .custom-select__option--is-focused:active {
      background-color: $grey-3;
    }

    // .custom-select__option--is-selected {
    //   background-color: $primary-color;
    // }

    .css-10wo9uf-option:active {
      background-color: white;
    }
  }
}